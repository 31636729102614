@media only screen and (min-width: 1025px) {
  .fid-1 {
    display: none;
  }

  .fid-2 {
    display: block;
  }

  .min-height {
    min-height: 510px;
  }

  .component-wrapper {
    min-height: 100%;
    position: relative;
  }
}

@media only screen and (max-width: 1024px) {
  .mobile {
    &.container {
      padding-top: 0px !important;
    }

    .fid-1 h4.title {
      margin-top: 0px !important;
    }

    .fid-1 {
      display: block;
    }

    .fid-2 {
      display: none;
    }
  }
}

@media only screen and (max-width: 992px) {
  html,
  body {
    overflow-x: hidden;
  }

  /** my profile page*/
  .scrollable-container {
    padding-top: 0 !important;
    overflow-x: hidden;
  }

  .pl-box-shadow-1 {
    box-shadow: none;
  }

  .profile-info {
    flex-direction: column;
    align-items: center;
    padding-top: 0 !important;

    .name-email-container {
      width: auto !important;
      text-align: center;

      .name {
        text-transform: capitalize;
        color: #003765;
      }
    }

    .initial-rounded {
      line-height: 49px;
    }
  }

  ul.nav {
    margin-top: 27px !important;

    li {
      a {
        padding: 12px 15px;
      }

      span.far,
      span.fas {
        font-size: 22px;
        position: relative;
        top: 2px;
        margin-right: 7px;
        margin-left: 8px;
      }
    }
  }

  .sidebar-wrapper {
    padding-bottom: 0px !important;
    padding-top: 0 !important;
  }

  .personal-information {
    padding: 15px !important;
  }

  .account-profile,
  .account {
    .no-padding-md {
      padding-left: 0 !important;
      padding-right: 0 !important;

      .personal-information {
        padding: 0 !important;

        h4.title {
          padding: 15px 30px !important;
          margin-top: 20px;
          font-size: 20px;
        }

        #accountForm {
          padding: 0 30px;
        }
      }
    }

    .btn-actions {
      padding-left: 30px;
      padding-right: 30px;
      flex-direction: column !important;
      align-content: center;
      align-items: center;

      button {
        max-width: 400px;
        width: 100%;
      }
    }
  }

  /** password page */
  .account-change-password,
  .account-two-factor-authentication {
    .col-12.col-sm-9,
    .col-12.col-sm-3 {
      flex: 0 0 100%;
      max-width: 100%;

      &.no-padding-md {
        padding-left: 0 !important;
        padding-right: 0 !important;

        .wrapper {
          padding: 0 !important;

          h4.title {
            padding: 15px 30px !important;
            margin-top: 20px;
            font-size: 20px;
            background-color: #fafbfc !important;
          }

          form {
            padding: 0 30px;

            .change-pass-container {
              flex-direction: column-reverse;
            }
          }

          .buttons {
            display: flex;
            align-items: center;

            button {
              margin: 0 auto;
              border-radius: 4px;
              background-color: #003765;
              border: 0;
              max-width: 400px;
              width: 100%;
              padding: 13px 15px;
              height: auto !important;
            }
          }

          .twofa-wrapper {
            padding: 0 30px !important;

            .tab-cont {
              margin: 0 !important;
            }
          }
        }
      }
    }
  }

  body[class*="complete-your-profile"],
  body[class*="complete-registration"],
  body[class*="secure-account"],
  body[class*="verify-account"] {
    .d-block.actions.pt-4.pb-3.mb-4 {
      button {
        padding: 13px 15px;
        height: auto !important;
      }
    }

    form {
      .row.justify-content-center {
        padding-left: 10px;
        padding-right: 10px;
      }
    }

    .title-mb {
      font-size: 20px;
      padding: 20px 25px !important;
      margin-top: 20px;
    }

    .plasma-header {
      .navbar-toggler,
      nav.nav-bar-right .profile-dropdown {
        display: none !important;
      }

      .navbar-brand {
        margin: 0 auto !important;
      }
    }

    .profile-complt-wrappper {
      box-shadow: none;
      border: 0;

      h3.title-mb {
        font-size: 20px;
        padding: 20px 25px !important;
        margin-top: 20px;
      }

      .wrapper.py-3.px-4 {
        padding: 0 !important;
        padding-top: 30px !important;
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
    }

    .buttons.mt-4 {
      display: flex;
      align-items: center;
      align-content: center;

      button {
        margin: 0 auto;
        border-radius: 4px;
        background-color: #003765;
        border: 0;
        max-width: 400px;
        width: 100%;
        padding: 13px 15px;
        height: auto !important;
      }
    }

    .verify-form {
      .text-right {
        text-align: center !important;
        margin-top: 50px;

        button {
          margin: 0 auto;
          border-radius: 4px;
          background-color: #003765;
          border: 0;
          max-width: 400px;
          width: 100%;
          padding: 13px 15px;
          height: auto !important;
        }
      }
    }
  }

  body.registration-success {
    &.app-white-labeled-dk {
      .scrollable {
        height: auto !important;

        .row.mt-4.cont-wl {
          margin-top: 30px !important;

          .access-app-logo {
            img {
              max-height: 60px;
            }
          }
        }

        .text-center {
          button {
            margin-bottom: 30px;
          }
        }
      }
    }

    .header {
      text-align: center;
    }

    .access-app {
      .access-app-logo {
        img {
          height: auto;
          max-height: 80px;
        }
      }

      .access-app-details {
        padding-top: 5px !important;
        padding-bottom: 0 !important;

        span.px-3.mt-2 {
          padding-left: 0 !important;
          padding-top: 0 !important;
        }
      }
    }
  }

  .section-progress {
    h6 {
      font-weight: normal !important;
      padding-top: 13px;
      color: #4c5256;
    }
  }

  .app-white-labeled-dk {
    overflow: auto !important;

    .scrollable-container {
      overflow: auto !important;
      height: auto !important;
    }

    .new-footer {
      padding-top: 30px;

      * {
        font-size: 15px;
      }

      .ltext {
        font-size: 15px !important;
        padding-top: 10px;
        padding-bottom: 20px;
        text-align: center;
        padding-left: 10px;
        padding-right: 10px;
      }

      .logo-partners {
        img {
          max-height: none !important;
          display: block;
          margin: 0 auto;
          max-width: 150px;
          margin-bottom: 15px;

          &.logo3 {
            max-height: 90px !important;
          }
        }
      }

      .copyright {
        flex-direction: column;
        font-size: 15px;

        .links {
          a {
            display: block;
            //text-decoration: none !important;
            padding-top: 2px;
            padding-bottom: 2px;
            font-size: 15px;

            &:last-of-type {
              margin-bottom: 20px;
            }
          }
        }

        .logo-right {
          margin-left: initial !important;

          img {
            margin-top: 0 !important;
            max-height: 53px !important;
            position: relative;
            left: -8px;
          }
        }
      }
    }

    .navbar-brand {
      margin-left: 0 !important;
    }

    .mat-select-trigger {
      top: 7px;
    }

    .white-labeled-icon {
      .navbar-brand {
        .pl-logo-1 {
          max-height: 40px !important;
          top: 0 !important;
        }
      }
    }

    .mat-option-text {
      color: #003765 !important;
    }

    .profile-complt-wrappper,
    .verify-form,
    .profile-secure-wrappper {
      h6 {
        font-weight: normal !important;
      }

      form {
        label {
          color: #4c5256 !important;
        }

        .mat-select-value,
        .mat-option-text {
          color: #003765 !important;
        }
      }

      .form-control {
        border-color: #e6ebef !important;
        height: 50px !important;
        box-shadow: none !important;
        color: #003765 !important;
      }
    }

    &.account-profile {
      .white-labeled-footer {
        display: block !important;
      }
    }
  }

  .account-profile,
  .account-change-password {
    label {
      color: #4c5256 !important;
    }

    .mat-select-value,
    .mat-option-text {
      color: #003765 !important;
    }

    .form-control {
      border-color: #e6ebef !important;
      height: 50px !important;
      box-shadow: none !important;
      color: #003765 !important;
    }
  }
}

@media only screen and (max-width: 768px) {
  .container {
    max-width: none !important;
  }

  .plasma-header {
    .nav-container {
      flex-direction: row !important;
      align-items: center;
    }
  }

  .account-two-factor-authentication {
    .force-mfa-warning {
      mat-icon {
        overflow: visible;
      }

      span.text {
        margin-left: 15px !important;
      }
    }
  }
}

@media only screen and (max-width: 576px) {
  .container {
    max-width: none !important;
  }

  .profile-complt-wrappper {
    .actions {
      .flex-row-reverse {
        flex-direction: column-reverse !important;

        button {
          margin-bottom: 10px;

          &.btn-outline-secondary {
            margin-right: 0 !important;
          }
        }
      }

      button.btn {
        height: 45px !important;
        font-weight: 500;
      }
    }

    .row {
      .col-6 {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }

    .country-frm {
      margin-top: 10px;
    }
  }

  .plasma-header {
    .nav-container {
      flex-direction: row !important;
      align-items: center;
    }
  }

  .sidebar-wrapper {
    margin-bottom: 10px;
  }

  .personal-information {
    .row {
      [class*="col-"] {
        flex: 0 0 100% !important;
        max-width: 100% !important;
      }
    }
  }

  body[class*="complete-your-profile"],
  body[class*="complete-registration"],
  body[class*="secure-account"],
  body[class*="verify-account"],
  body[class*="complete-tara"] {
    .section-progress {
      .progress-no {
        width: 100%;
      }

      .complete-profile-wrapper {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }

    .col-12.col-sm-9.pt-4 {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  body.registration-success {
    .access-app {
      .access-app-logo,
      .access-app-details {
        flex: 0 0 100%;
        max-width: 100%;
        text-align: center;

        h4 {
          margin-bottom: 0;
          padding-bottom: 0;
        }
      }

      .access-app-logo {
        justify-content: center !important;

        img {
          margin-bottom: 20px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  syn-synlab-header {
    position: relative !important;
  }

  syn-synlab-header + router-outlet + * {
    display: block;
    overflow: auto;
    height: calc(100vh - 76px);
  }

  syn-synlab-header.header-invisible + router-outlet + * {
    height: 100vh;
  }
}

@media only screen and (min-height: 910px) {
}

@media only screen and (max-width: 768px) {
  app-change-email-pending-modal {
    .mat-mdc-dialog-actions {
      justify-content: center !important;
      gap: 10px;

      button {
        width: 100%;
        max-width: 250px;
        justify-content: center;
        margin: 0 !important;
      }
    }

    .mail span {
      word-break: break-word;
    }
  }

  body.account-two-factor-authentication {
    app-mat-dialog {
      mat-dialog-actions {
        flex-direction: column !important;
        gap: 10px;

        button {
          width: 100%;
          max-width: 300px;
          margin: 0 !important;
        }
      }
    }
  }

  body[class*="registration-secure-account"] {
    .stepper-container {
      padding-left: 10px;
      padding-right: 10px;
    }

    app-register-mfa {
      padding: 10px 20px;
      display: block;
    }

    .card-container {
      padding: 25px !important;
    }

    p.notes {
      word-break: break-word;
    }

    .component-wrapper {
      & > .d-flex {
        display: block !important;
      }

      .actions {
        padding-left: 0 !important;
        margin-top: 20px;
      }
    }
  }

  .cancel-change-email-modal {
    min-width: auto !important;

    h4 {
      padding-top: 20px;
      text-align: center;
    }

    p {
      text-align: center;
    }

    .btn-wrapper {
      flex-direction: column;
      justify-content: center;
      align-items: center;

      button {
        width: 100% !important;
        max-width: 300px;
        margin-bottom: 10px;
        margin-left: 0 !important;
      }
    }

    .wrap-loading {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

@media only screen and (max-height: 600px) {
  .preview-wrapper .preview-card-wrapper .preview-card .preview-content {
    max-height: 60vh !important;
  }
}
