$primary: #0076ad;
@import "../node_modules/sweetalert2/dist/sweetalert2.min.css";
@import "./app/themes/themes.scss";
@import "./app/themes/pure-css-loader.scss";
@import "./app/themes/date-picker.scss";
@import "./app/themes/_variables.scss";

@mixin synlab-default-btn {
  justify-content: center;
  align-items: center;
  padding: 15px 24px 15px 20px;
  background: #003765;
  border-radius: 68px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #fff;
  text-decoration: none !important;
  display: inline-flex !important;
}

body {
  overflow-y: scroll;
  overflow-y: overlay;
  &[class*="medio"] {
    .header-visible {
      display: none !important;
    }
  }
}
html,
body {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  .plasma-header {
    .container {
      max-width: 1700px;
    }
  }
}

// init component loader
.init-loader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 9999999;
  display: flex;
  align-items: center;
  align-content: center;
  background-color: #ffffff;
  span {
    margin-left: auto;
    margin-right: auto;
  }
}

.synlab.my-custom-class {
  top: -18px !important;
}

.my-custom-class .tooltip-inner {
  background-color: #0077ad !important;
}
.my-custom-class .arrow::before {
  border-bottom-color: #0077ad !important;
}

.disabled {
  span,
  p,
  i {
    color: rgba(#3b3b3b, 50%) !important;
  }
  a {
    color: rgba($primary, 50%) !important;
    cursor: default !important;
    &:hover {
      color: rgba($primary, 50%) !important;
    }
  }

  .badge-success {
    color: rgba(#fff, 50%) !important;
    // background-color: rgba($success, 50%) !important;
  }

  i.clickable {
    cursor: default !important;

    &:hover {
      color: rgba(#3b3b3b, 50%) !important;
    }
  }
}

.clickable {
  cursor: pointer;
}
/**
  For error message inside form-group

  NOTE::
    Add `position-absolute` class for top:100%
    and add `position-relative` class to
    `form-group` div to anchor to that element
*/
.form-group .form-group__error {
  top: 100%;
}

/** override for dialog top adjustment */

html.cdk-global-scrollblock {
  top: initial !important;
  overflow-y: hidden;
  body {
    overflow-y: hidden;
  }
}
/** overrides for mat-select*/
mat-select.mat-select-disabled,
mat-select.mat-select-disabled:focus {
  background-color: #e9ecef;
  opacity: 1;
  color: #495057;
  div,
  span {
    color: #495057;
  }
}

/*custom style for mobile input with country*/

.iti {
  display: block !important;
  //margin-bottom: 20px;
  margin-bottom: 12px;
}

.iti .dropdown-menu.country-dropdown {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-color: #c7cace;
  margin-top: -1px;
}

.iti .iti__country-list {
  box-shadow: none;
  font-size: 14px;
  margin-left: 0;
  //width: 400px;
  //max-height: 170px;
}

.iti__flag-container.open + input {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.iti .search-container input {
  font-size: 14px;
  border-color: #c7cace;
  border-radius: 0;
  padding: 5px 10px;
}

.iti .search-container input:focus {
  outline: none;
}

.iti input,
.iti input[type="text"],
.iti input[type="tel"] {
  height: 40px;
}

@media screen and (max-width: 479px) {
  .iti .iti__country-list {
    width: 88.3vw;
  }
}

ngx-intl-tel-input input {
  height: 44px;
  margin-bottom: 20px;
  padding: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: #c7cace;
  border-radius: 4px;
  font-size: 18px;
}

ngx-intl-tel-input.ng-invalid.ng-touched input {
  border: 1px solid #c0392b;
}

ngx-intl-tel-input input:hover {
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.24);
}

ngx-intl-tel-input input:focus {
  outline: none !important;
  border-color: #3498db;
  box-shadow: 0 0 0 0 #000;
}

ngx-intl-tel-input input::-webkit-input-placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input:-ms-input-placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input::-ms-input-placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input::placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input[disabled] {
  background-color: #e5eaf1;
}

.normal-size-tooltip {
  font-size: 0.75rem;
}

/** logo styles adjustments */
// .white-labeled-icon {
//   top: -3px !important;
//   .navbar-brand {
//     padding: 0 0;
//     margin-right: 0 !important;
//     .pl-logo-1 {
//       max-height: 55px;
//     }
//   }
// }
.new-header .navbar-brand img {
  max-height: 55px;
}

[name="phone"] input[type="tel"] {
  width: 100% !important;
  padding-left: 85px !important;
  height: 45px;
  border: 1px solid #f2f5f7;
  border-color: #abb5bd;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.13);
}
[name="phone"] .iti__selected-flag.dropdown-toggle {
  width: 80px !important;
}

body[class*="complete-your-profile"],
body[class*="complete-registration"],
body[class*="secure-account"],
body[class*="verify-account"],
body.registration-success {
  .plasma-header {
    nav.nav-bar-right .profile-dropdown,
    .navbar-toggler {
      display: none !important;
    }
    .navbar-brand {
      margin: 0 auto;
    }
  }
  ul.navbar-nav {
    display: none;
  }
}

.app-white-labeled-dk {
  overflow: auto !important;
  .scrollable-container {
    overflow: auto !important;
    height: auto !important;
  }
  .new-header {
    .navbar-brand {
      img {
        max-height: 35px;
        position: relative;
        top: 0 !important;
      }
    }
  }
  footer.white-labeled-footer {
    font-size: 0.85rem;
    height: 100%;
    display: flex !important;
    align-content: flex-end;
    align-items: flex-end;
  }

  // complete registration page
  &.registration-complete-registration,
  &[class*="complete-registration"] {
    .scrollable {
      overflow-y: initial !important;
    }
    .outer-wrapper {
      padding-top: 20px;
    }
  }

  .scrollable {
    height: auto !important;
    overflow-y: initial !important;
  }
}

.text-synlab-primary {
  color: #003765;
}
.text-synlab-secondary {
  color: #0077ad;
}
.no-outline,
.no-outline:active,
.no-outline:focus {
  outline: none;
  box-shadow: none;
}
.break-word {
  word-break: break-word;
}
/*toggle*/
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: $primary;
}

input:focus + .slider {
  box-shadow: 0 0 1px $primary;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

input[disabled]:checked {
  cursor: default !important;
  & + .slider {
    background-color: #dfdfdf;
    cursor: default;
  }
}

.synlab-table {
  border: 1px solid #f2f5f7;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.13);
  thead {
    background-color: #f2f5f7;
    th {
      height: 24px;
      color: #003765;
      font-size: 14px;
      font-weight: bold;
      line-height: 24px;
      border: 1px solid #e6e6e6 !important;
      &.fixed-width-th {
        width: 50%;
      }
    }
  }
  tbody {
    tr {
      td {
        padding: 10px 15px;
        color: #003765;
        font-size: 14px;
        border: 1px solid #e6e6e6;
      }
      &.inactive-label {
        td {
          color: #9bb0c3;
        }
      }
    }
  }
}

// styles for language selection loading
.language-selector-loading {
  .spinner-three-bounce {
    display: none;
  }
}

.register-profile-view-container .invalid-phone-number,
.form-control.invalid-phone-number {
  border: #dd0031 solid 1px !important;
}

.privacy-policy,
.terms-of-use {
  .cdk-overlay-container {
    display: none !important;
  }
}

app-complete-profile {
  .custom-date-picker .input-group .input-group-prepend {
    border-color: #d9dde0;
  }

  .custom-date-picker input,
  .custom-date-picker input[readonly] {
    border-color: #d9dde0;
  }

  .custom-date-picker .input-group-prepend.invalid,
  .custom-date-picker input.invalid {
    border-color: #dc3545;
  }

  [name="phone"] input[type="tel"] {
    border-color: #d9dde0;
  }
}

span.info-circle-icon {
  background: #ebeff1;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  text-align: center;
  font-family: serif;
  color: #6f89ae;
  font-weight: bold;
  font-style: italic;

  &::before {
    content: "i";
  }
}

.sl-tooltip-panel {
  color: #003765 !important;
  background: #e5f0f8;
  font-size: 14px;
}

.notication-page-wrapper {
  .mat-radio-label .mat-radio-label-content,
  .mat-checkbox-label {
    font-size: 14px;
  }

  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: #003867;
  }
  .mat-radio-button.mat-accent .mat-radio-inner-circle {
    background-color: #003867;
  }

  .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
    background: #003867;
  }
  .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: #003867;
  }
}

/**
* utility
*/
.decoration-none,
.decoration-none:focus,
.decoration-none:hover {
  text-decoration: none;
}

.set-mfa-warning {
  .swal2-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 150%;
    margin-top: 30px;
  }

  .swal2-content {
    font-weight: 400;
    font-size: 15px;
    line-height: 28px;
    letter-spacing: -0.01em;
  }

  .swal2-styled.swal2-confirm {
    text-transform: uppercase;
    font-size: 14px;
  }
}

syn-synlab-header {
  position: sticky;
  top: 0;
  z-index: 100;
  display: block;

  .lib-header {
    a {
      color: inherit;
    }
    button:focus {
      outline: none;
    }
  }
}

body[class*="send-forgot-password"] {
  overflow: hidden;
}

.index-spinner {
  display: none;
}

.spinner-border {
  color: $primary-skyblue;
}

.signin-redirecting .index-spinner {
  display: initial;
}
.signin-redirecting {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  app-root {
    display: none;
  }
}

@import "./app/plasma-ui-common/synlab-access-ui-components/spinner/spinner.component.scss";

/**
 * Styles for dxp agreement and privacy policy
 */
.is-dxp {
  background-image: url("/assets/dxp/img/background.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 30vh;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  label,
  p {
    font-family: "Open Sans", sans-serif !important;
    color: #003765 !important;
    font-style: normal !important;
  }

  label {
    font-weight: 600 !important;
  }

  a {
    text-decoration: underline !important;
  }

  a,
  p,
  label {
    font-size: 16px !important;
    line-height: 24px !important;
  }

  button {
    padding: 14px 34px !important;
    background: linear-gradient(90deg, #f9b53a 0%, #ffd784 100%) !important;
    border-radius: 50px !important;
    font-family: "Open Sans", sans-serif !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    letter-spacing: 0.05em !important;
    text-transform: uppercase !important;
    color: #003765 !important;
    margin-top: 43px !important;
    border: none !important;
  }
}

/**Icon Tooltip Overrides**/
.register-profile-view-container,
.page-tara-register-container,
.notification-settings {
  ngb-popover-window .arrow {
    display: none !important;
  }
  .popover {
    background-color: #003765;
    border-radius: 8px;
  }

  .popover-body {
    padding: 15px 15px;
    color: #ffffff !important;
  }

  .icon-desktop {
    display: block;
  }

  .icon-mobile {
    display: none;
  }

  .close {
    display: none;
  }
}

/**Icon Tooltip Overrides**/
@media only screen and (max-width: 820px) {
  .register-profile-view-container,
  .page-tara-register-container,
  .notification-settings {
    .close {
      display: block;
    }
    .icon-mobile {
      display: block !important;
    }
    .icon-desktop {
      display: none !important;
    }
  }
}

@media (max-width: 767px) {
  .is-dxp {
    .background {
      display: none !important;
    }
  }
}
/**
 * [end] Styles for dxp agreement and privacy policy
 */

.loading-indicator {
  .app-spinner {
    position: relative !important;
    animation: 2s ease 0s normal forwards 1 fadeInSpinner;

    &.d-fixed {
      position: fixed !important;
    }
  }
}
@keyframes fadeInSpinner {
  0% {
    opacity: 0.05;
    transform: scale(0.5, 0.5);
  }
  80% {
    opacity: 0.5;
    transform: scale(0.8, 0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}

.signin-redirecting {
  .index-spinner {
    display: flex;
    position: fixed;
    top: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: animloader 1s linear 1;

    .loader {
      width: 48px;
      height: 48px;
      display: inline-block;
      position: relative;
      opacity: 0;
      animation: enter;
      animation-fill-mode: forwards;
      animation-delay: 500ms;
    }
    .loader::after,
    .loader::before {
      content: "";
      box-sizing: border-box;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background: #f9b232;
      position: absolute;
      left: 0;
      top: 0;
      animation: animloader 2s linear infinite;
    }
    .loader::after {
      animation-delay: 1s;
    }
    @keyframes animloader {
      0% {
        transform: scale(0);
        opacity: 1;
      }
      100% {
        transform: scale(1);
        opacity: 0;
      }
    }
    @keyframes enter {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 1;
      }
    }
  }
}

app-ward-list {
  .badge {
    &.badge-pill {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.configurable-form-field {
  input#phone {
    height: 58px;
  }
  .input {
    border-color: #bfddea;
    &:disabled {
      background-color: #f2f8fb;
    }
  }
  label {
    color: #003765;
    font-size: 16px;
    font-style: normal;
    font-family:
      Open Sans,
      sans-serif;
  }
}

body.tenant-ee {
  syn-activate-account {
    .background {
      display: none !important;
    }
  }
}
.change-email-veriifed {
  syn-activate-account {
    .background {
      display: none !important;
    }

    .status-success {
      a.button {
        background: #f2f8fb !important;
        border: 1px solid #003765 !important;
      }
    }
  }
}

.documentNumberTooltip {
  font-family: "Open Sans", Roboto, "Helvetica Neue", sans-serif;
  font-size: 13px;
  position: relative;
  top: 6px;
}

body.tenant-cz {
  ul.lib-header-menu {
    &.col-gap-0 {
      column-gap: initial !important;
    }
  }
  .docidtooltip {
    font-size: 11px;
  }
}

.mat-dialog-container {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.mat-mdc-radio-button.mat-accent {
  --mat-radio-checked-ripple-color: #003765;
  --mdc-radio-selected-focus-icon-color: #003765;
  --mdc-radio-selected-hover-icon-color: #003765;
  --mdc-radio-selected-icon-color: #003765;
  --mdc-radio-selected-pressed-icon-color: #003765;
}

.mdc-label {
  font-size: 18px !important;
}

.mat-mdc-select {
  height: auto !important;

  .mat-mdc-select-trigger {
    min-height: 40px;
  }
}

.fi-professional-form-wrapper {
  display: flex;
  gap: 30px;
  flex-direction: column;

  app-configurable-form-field:nth-child(2) {
    margin-top: -20px;
  }
}

body.tenant-fi {
  nav.is-mobile {
    background-color: white !important;

    .lib-header-brand img {
      filter: unset !important;
    }

    .lib-header-toggle {
      display: flex;
      justify-content: flex-end;

      svg {
        path {
          fill: #003765;
        }
      }
    }
  }
}

/** add red border if phone is invalid */
.formV1.phone-invalid {
  input.input {
    border-color: #dc3545 !important;
  }
}

.fi-profile {
  .lib-header.is-mobile {
    .lib-header-toggle,
    .lib-header-section {
      mat-icon {
        svg {
          path {
            fill: #0077ad !important;
          }
        }
      }
    }
  }
}

.consent_trigger-form {
  .tooltip-description {
    .tooltip-wrapper {
      p {
        margin-bottom: 0;
        color: #f2f2f2;
      }

      a {
        color: #fff;
        text-decoration: underline;
      }
    }
  }
}

.mat-datepicker-input {
  border: 0 !important;
}

.mat-icon.mat-primary {
  color: #2f7eb1;
}
.iti__selected-flag {
  z-index: 1;
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 6px 0 8px;
}
.iti__flag-container {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 1px;
  right: auto;
  left: 0;
}

.iti input {
  margin: 0 !important;
}

app-locale-picker {
  mat-select {
    padding: 10px 20px;
    border: 1px solid #bfddea;
    border-radius: 10px;
  }
}

mat-option {
  .mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked::after {
    color: #0077ad !important;
  }

  .mdc-list-item__primary-text {
    color: #000 !important;
  }

  &.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
    color: #003765 !important;
  }
}

body[class*="registration"] {
  .mat-mdc-select-panel {
    margin-left: -20px;
    margin-top: 12px;
  }
}

.agreements-container {
  & > * {
    margin-right: 5px;
  }

  a {
    color: #0056b3 !important;
  }
}

ngx-intl-tel-input {
  ul {
    height: 300px;
  }
}

.btn-danger {
  background-color: #dc3545 !important;
  border-color: #dc3545 !important;
  color: white !important;
}
.btn-primary {
  color: white !important;
  background-color: #003765 !important;
  border-color: #003765 !important;
}

app-managed-privacy-policy {
  p {
    color: #212529;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin: 0 0 16px 0;
  }

  h3 {
    font-size: 28px;
    font-weight: 400;
    line-height: 33px;
    margin: 0 0 8px 0;
    color: #212529;
  }

  table {
    width: 100%;
  }
}

app-legal-documents-preview {
  h3 {
    font-size: 28px;
    font-weight: 400;
    line-height: 33px;
    margin: 0 0 8px 0;
  }
  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 16px;
  }
}

body.account-two-factor-authentication {
  app-mat-dialog {
    .mat-mdc-dialog-content {
      color: #003765 !important;
      font-size: 18px;
      padding-top: 35px;
    }
    mat-dialog-actions {
      button {
        @include synlab-default-btn;
        background-color: #fff;
        color: #616161;
        padding-left: 24px;
        border: 2px solid #e7e7e7;
        height: 48px;
        min-width: 140px;
        &.btn-primary {
          border-color: #003765 !important;
        }
      }
    }
  }
}
.privacy-whitelabeled {
  .ng2-pdf-viewer-container {
    overflow-y: auto;
    height: calc(75vh - 50px) !important;
  }
}
app-legal-documents-preview {
  .preview-actions {
    button {
      @include synlab-default-btn;
      max-height: 48px;
      min-width: 170px !important;
    }
  }

  *:not(i) {
    font-family: "Open Sans", sans-serif;
  }
}

.account-profile {
  .configurable-form-field {
    input {
      &.control-invalid {
        border-color: #dc3545 !important;
      }
    }
  }
}
